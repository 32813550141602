exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-sponsors-sanity-sponsor-slug-tsx": () => import("./../../../src/pages/sponsors/{SanitySponsor.slug}.tsx" /* webpackChunkName: "component---src-pages-sponsors-sanity-sponsor-slug-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("./../../../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */),
  "component---src-pages-take-part-tsx": () => import("./../../../src/pages/take-part.tsx" /* webpackChunkName: "component---src-pages-take-part-tsx" */),
  "component---src-pages-updates-sanity-update-slug-tsx": () => import("./../../../src/pages/updates/{SanityUpdate.slug}.tsx" /* webpackChunkName: "component---src-pages-updates-sanity-update-slug-tsx" */),
  "component---src-pages-updates-tsx": () => import("./../../../src/pages/updates.tsx" /* webpackChunkName: "component---src-pages-updates-tsx" */),
  "component---src-pages-volunteer-tsx": () => import("./../../../src/pages/volunteer.tsx" /* webpackChunkName: "component---src-pages-volunteer-tsx" */),
  "component---src-pages-vote-tsx": () => import("./../../../src/pages/vote.tsx" /* webpackChunkName: "component---src-pages-vote-tsx" */)
}

